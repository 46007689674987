a {
  margin: auto auto;
  text-decoration: none;
}
.projects-page {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0 .5em;
  margin-top: 10em;
  margin-bottom: 10em;
}

.project-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  box-shadow: 5px 10px 18px #888888;
  height: 15em;
  width: 15em;
  border-radius: 5em;
  background-color: rgb(233, 223, 223);
}

.projects-page a {
  background-color: unset;
}

.projects-page a:visited {
  color: black;
}

@media(min-width: 48em) {
  .projects-page {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2em;
    justify-content: center;
    align-items: center;

  }
  .project-links {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 10px 18px #888888;

  }

}