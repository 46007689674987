.personalProjects {
  width: 100%;
  height: 20em;
  margin: auto auto;
  margin-top: 10em;
  margin-bottom: 10em;  
  text-align: center;
  align-items: center;  
}

.personalProjects p {
  font-size: 3em;
}

