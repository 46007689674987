@media screen and (min-width: 300px) {
    nav {
    font-family: 'Arvo', serif;
    display: flex;
    flex-direction: row;
    background-color: #7BDFF2;
    align-items: center;
    width: 100%;
    height: 5em;
    }

  .title {
      justify-content: flex-start;
      margin: unset;
      font-size: 1.5em;
      padding-left: 1em;
      background-color: #7BDFF2;

  }
  
  .nav-toggle {
      cursor: pointer;
      position: absolute;
      right: 2em;
      
  }


  .nav-menu {
      position: absolute;
      top: 4.95em;
      right: 1em;
      list-style: none;
      background-color: #7BDFF2;
      padding-left: 1em;
      padding-right: 1em;
      margin: unset;
      border: solid 1px black;
      border-top: none;
  }


  .nav-list {
      margin-bottom: .5em;
      background-color: #7BDFF2;

  }

  .hidden {
      display: none;
  }

  .nav-toggle div {
      background-color: #7BDFF2;
  }

  nav a {
      text-decoration: unset;
      background-color: #7BDFF2;
      color: black;
  }

  nav a:visited {
      color: black;
  }




}

@media screen and (min-width: 750px) {
  
  .nav-toggle {
      display: none;
  }

  .nav-menu {
      display: unset;
      top: 1.75em;
      justify-content: space-evenly;
      border: none;
  }

  .nav-list {
      display: inline;
      font-size: 18px;
      font-weight: bolder;
  }

  #projects, #contactMe {
      padding-right: 2em;
  }

}

