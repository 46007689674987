
@media screen and (min-width: 350px) {
  .footer {
    font-family: 'Arvo', serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 5em;
    background-color: #E5ECF4;
    justify-content: flex-end;
  }

}

@media screen and (min-width: 750px) {
  .footer {
      bottom: -10em;
      justify-content: flex-end;
  }
}
