@media screen and (min-width: 320px) {
  .aboutMe {
    line-height: 2em;
    font-size: 1.1em;
    margin-top: 5em;
    margin-bottom: 8em;
    text-align: center;
  }

  .profile {
    max-width: 15em;
    margin-top: 2em;
    box-shadow: .5em .5em 2em rgb(0, 195, 255);
  }

  h2 {
    text-align: center;
    font-size: 2em;
  }

  .aboutSection {
    text-align: left;
    display: inline-block;
    margin: 1em 1em;
    line-height: 2em;  
  }

}

@media screen and (min-width: 750px) {
  .aboutSection {
    margin-left: 5em;
  }
  
  .profile {
    max-width: 30em;
    height: 20em;
    margin-left: 4em;
    margin-top: unset;
  }

  .resume {
    display: block;
    width: 8.5em;
    margin-top: 5em;
    border-radius: 4em;
    background-color: rgb(136, 206, 230);
    color: black;
  }

}
  

@media screen and (min-width: 1273px) {
  .aboutSection {
    width: 45%;

  }
}